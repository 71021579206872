import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import Dashboard from './DashboardPage';
import './MainMessage.css'; 

const MainMessage = () => (
  <div className="main-message">
    <p>wax is now a free 1.20.4 injectable client with a webgui. it was paid, but namecheap upped their fees, so we made it free.</p>
    <p>we're not supporting wax anymore. instead, we're making a new cheat: <a href="https://remnant.wtf">remnant</a>.</p>
    <p><span style={{color: 'red'}}>access the dashboard at <a href="https://wax.rest/login">wax.rest/login</a> using "freeuser" for both username and password.</span></p>
    <p>thanks for using wax! if i forgot to remove the prot, hit us up on <a href="https://discord.com/invite/C6rxGMxZBX">discord</a> (no other support tho).</p>
  </div>
)

const App: React.FC = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
    
    return () => {
      document.documentElement.style.scrollBehavior = '';
    };
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-black text-white">
        <Routes>
          <Route path="/" element={<MainMessage />} />
          <Route path="/legacy" element={<LandingPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;